/**
 * Module dependencies,
 */

import { Button } from 'src/components/core/button';
import { Container } from 'src/components/core/container';
import {
  Description,
  Title
} from 'src/components/layout/authentication/styles';

import { GetStaticProps, NextPage } from 'next';
import { SEO } from 'src/components/core/seo';
import { SSGProps } from 'src/types/app';
import { routes } from 'src/core/constants/routes';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useSession } from 'src/providers/session';
import { useTranslation } from 'next-i18next';

/**
 * `NotFound` page.
 */

const NotFound: NextPage = () => {
  const { t } = useTranslation('common');
  const { user } = useSession();
  const route = user ? routes.dashboard : routes.authentication.signIn;

  return (
    <>
      <SEO title={t('common:notFound.title')} />

      <Container>
        <Title>{t('common:notFound.title')}</Title>

        <Description>{t('common:notFound.description')}</Description>

        <Button href={route} stretch>
          {t('common:notFound.button')}
        </Button>
      </Container>
    </>
  );
};

/**
 * Export `getStaticProps`.
 */

export const getStaticProps: GetStaticProps<SSGProps> = async ({ locale }) => {
  return {
    props: {
      ...(await serverSideTranslations(locale as string, ['common', 'auth'])),
      layout: 'auth'
    },
    revalidate: 60
  };
};

/**
 * Export `NotFound` page.
 */

export default NotFound;
